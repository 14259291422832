import React, {useEffect, useState} from 'react'
import Faq from 'react-faq-component';
import faqData from '../faq.json'
import faqDataTuke from '../faqTuke.json'
import faqDataUPJS from '../faqUPJS.json'
import faqDataVeterina from '../faqVeterina.json'
import img from '../images/questions.svg'

const CustomFAQ = ({title, type, faq}) => {

  const [JSON, setJSON] = useState();

  useEffect(() => {
    switch(faq) {
      case 'tuke':
        setJSON(faqDataTuke)
        break;
      case 'upjs':
        setJSON(faqDataUPJS)
        break;
      default:
        setJSON(faqDataVeterina)
        break;
    }
  },[]);

  return (
    <React.Fragment>
      <section className='faq'>
        {(type =='2') ? 
        (
          <div className="container">
            <h3 className='text-center'>{title}</h3>
            {
              <Faq data={JSON}/>
            }
            
          </div>
        ) 
        : 
        (
          <div className="container d-flex">
            <div className="col-12 col-lg-6 d-flex flex-column justify-content-between">
              <h3>{title}</h3>
              <img className='w-100' src={img} alt="" />
              <div className="faq--contact">
                  <a className='btn btn--invert' href='tel: +38 067 163 29 09'>Залишилися питання?</a>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <Faq data={faqData}/>
            </div>
            <div className="faq--contact-mobile">
                <a className='btn btn--invert' href='tel: +38 067 163 29 09'>Залишилися питання?</a>
            </div>
          </div>
        )
        }
      </section>
    </React.Fragment>
  )
}

export default CustomFAQ
