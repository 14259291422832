import React from 'react'
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
import CustomSwiper from './CustomSwiper';

const Teaser = ({id, headline, img1, description1, img2, description2 ,img3, description3 ,img4, description4, count, firstLink, secondLink, thirdLink, link, title1, title2, title3, slider, backInfo1, backInfo2, backInfo3, backInfo4}) => {
  function setStartPage (){
    window.scrollTo(0, 0);
    
  }
  return (
    <React.Fragment>
      <section id={id} className='teaser'>
        {(count == '4') ? (
          <div className="container">
            {headline && <h3 className='text-center mb-5'>{headline}</h3>}
            <div className="cards swap gap-4">
              <Link to={link && `/#${link}`} className="cardd w-25">
                <div className="content">
                  <div className="front">
                    {slider === 'true' 
                    && 
                    <CustomSwiper 
                      img1={img1}
                      img2={img2}
                      img3={img3}
                      img4={img4}
                    />}
                    <img className='img' src={img1} alt="" />
                    <p className="card--description p-3">{description1}</p>
                    <div className="mobile">
                      <p className="card--description p-3">{description2}</p>
                      <p className="card--description p-3">{description3}</p>
                      <p className="card--description p-3">{description4}</p>
                    </div>
                  </div>
                  <div className="back">
                    <p>{backInfo1}</p>
                  </div>
                </div>


                
              </Link>
              <Link to={link && `/#${link}`} className="cardd w-25">
                <div className="content">
                  <div className="front">
                    <img className='' src={img2} alt="" />
                    <p className="card--description p-3">{description2}</p>
                  </div>
                  <div className="back">
                    <p>{backInfo2}</p>
                  </div>
                </div>
              </Link>
              <Link to={link && `/#${link}`} className="cardd w-25">
              <div className="content">
                  <div className="front">
                    <img className='' src={img3} alt="" />
                    <p className="card--description p-3">{description3}</p>
                  </div>
                  <div className="back">
                    <p>{backInfo3}</p>
                  </div>
                </div>
              </Link>
              <Link to={link && `/#${link}`} className="cardd w-25">
              <div className="content">
                  <div className="front">
                    <img className='' src={img4} alt="" />
                    <p className="card--description p-3">{description4}</p>
                  </div>
                  <div className="back">
                    <p>{backInfo4}</p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <div className="container w-lg-50">
            {headline && <h3 className='text-center mb-5'>{headline}</h3>}
            <div className="cards gap-lg-5">
              <NavLink onClick={setStartPage} className="card w-100 w-lg-50" to={firstLink}>
                  <img className='' src={img1} alt="" />
                  <div className="text--wrapper p-4">
                    <h4 className="title mb-3">{title1}</h4>
                    <p className="card--description m-0">{description1}</p>
                  </div>
              </NavLink>
              <NavLink onClick={setStartPage} className="card w-100 w-lg-50" to={secondLink}>
                  <img className='' src={img2} alt="" />
                  <div className="text--wrapper p-4">
                    <h4 className="title mb-3">{title2}</h4>
                    <p className="card--description m-0">{description2}</p>
                  </div>
              </NavLink>
              <NavLink onClick={setStartPage} className="card w-100 w-lg-50" to={thirdLink}>
                  <img className='' src={img3} alt="" />
                  <div className="text--wrapper p-4">
                    <h4 className="title mb-3">{title3}</h4>
                    <p className="card--description m-0">{description3}</p>
                  </div>
              </NavLink>
            </div>
          </div>
        )}
      </section>
    </React.Fragment>
  )
}

export default Teaser
