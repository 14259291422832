import React from 'react'
import Hero from '../components/Hero'
import PriceModule from '../components/PriceModule'
import Application from '../components/Application'
import Teaser from '../components/Teaser'
import cardImg1 from '../images/eduEasy/bed.svg'
import cardImg2 from '../images/eduEasy/cooking.svg'
import cardImg3 from '../images/eduEasy/laundry.svg'
import cardImg4 from '../images/eduEasy/studying.svg'
// import video from '../images/videoplayback.webm'
import firstUniv from '../images/rektorat-budova.jpg'
import secondUniv from '../images/areal_tuke_002.jpg'

import hero from '../images/hero_prices.svg'

const Price = () => {
  return (
    <React.Fragment>
      {/* <Hero 
        title="Безкоштовна освіта в Словаччині" 
        description="Пройдемо цей шлях разом - від першої заяви до першого візиту до університету!" 
        img={hero}
        color='pink'

      /> */}
      <PriceModule 
        img1={firstUniv}
        img2={secondUniv} 
        title1="BASIC:"
        title2="COMFORT:"
        title3="PREMIUM:"
        headline=''
        link="/price#price-form"
      />
      {/* <Hero 
        title="Edu: Easy" 
        description="Безпечна програма для дівчат
Залишилось 35 місць" 
        btnText="Дізнатися більше"
        link="/edueasy"
        type='2'
        img={hero}
      />
      <Teaser 
        count='4'
        img1={cardImg1}
        img2={cardImg2}
        img3={cardImg3}
        img4={cardImg4}
        description1="Безпечний гуртожиток"
        backInfo1="Завдяки електричному замку з чіпом та камерам спостереження, ми можемо обіцяти вам, що ваша донька матиме спокійний та безпечний сон."
        description2="Комфортні кімнати" 
        backInfo2="Кожна кімната обладнана власним душем і туалетом для максимального комфорту."
        description3="Сучасне обладнання та швидкий інтернет" 
        backInfo3="Ми розмістили 10 wifi роутерів по всій будівлі, щоб забезпечити високошвидкісний інтернет у кожній частині гуртожитку. У гуртожитку ви знайдете дві повністю обладнані кухні з сучасною технікою. Крім того, ми безкоштовно надаємо нову пральну та сушильну машини, щоб зробити ваше перебування ще більш комфортним."
        description4="Ідеальне середовище для концентрації на навчанні"
        backInfo4="Для студенток, які потребують тиші для навчання, є кімната для занять, що працює цілодобово."
        slider='true'
      /> */}
      <Application
        title="Залиште запит"
        id='price-form'
        page='Price'

      />
    </React.Fragment>
  )
}

export default Price
