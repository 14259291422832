import React from 'react'
import logo from '../images/logo-no-background.png'
import facebook from '../images/facebook.svg'
import instagram from '../images/instagram.svg'
import telegram from '../images/telegram.svg'
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
const Footer = () => {

  function setStartPage (){
    window.scrollTo(0, 0);
  }

  return (
    <footer className='footer'>
      <div className="container d-flex footer--wrapper">
        <div className="contacts w-lg-50">
          <a href='tel:'>+380 99 25 88 922</a>
          <a href='tel:'>+421 949 497 673</a>
          <p>Alejová 2, 04011 Košice</p>
        </div>
        <div className="socials w-lg-50">
          <p>Наші соціальні мережі:</p>
          <div className="links">
            <a href="https://www.facebook.com/eduke.com.ua">
              <img src={facebook} alt="" />
            </a>
            <a href="https://www.instagram.com/eduke.com.ua?igsh=MWZ0bHNiMGJkYThlNw==">
              <img src={instagram} alt="" />
            </a>
            <a href="https://t.me/edukecomua">
              <img src={telegram} alt="" />
            </a>
          </div>
        </div>
        <nav className="footer--navigation d-flex flex-column mt-0 gap-2 w-lg-50">
          <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/'>Головна</NavLink>
          <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/language'>Вивчення мови</NavLink>
          <Link onClick={setStartPage} to="/#universities">Університети</Link>
          <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/price'>Вартість</NavLink>
          <Link onClick={setStartPage} to="/#about">Про нас</Link>
          {/* <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/edueasy'>Edu: Easy</NavLink> */}
        </nav>
      </div>
      <div className="w-100 additional--footer">
        <div className="container d-flex justify-content-between">
          <p>© 2023 EDU:KE    Всі права захищені</p>
          <p>Політика конфіденційності</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
