import React from 'react'
import Hero from '../../components/Hero'
import CustomFAQ from '../../components/CustomFAQ'
import Application from '../../components/Application'
import Banner from '../../components/Banner'
import hero from '../../images/univerities/veterina/logo_veterina.png' //поміняти на свг
import bannerImg from '../../images/veterina.jpg'

const Veterina = () => {
  return (
    <div className='veterina'>
      <Hero 
        title="Університет ветеринарної справи та фармацевтики в Кошице" 
        description="Univerzita veterinárskeho lekárstva a farmácie v Košiciach" 
        btnText="Отримати консультацію"
        link="#tuke-form"
        img={hero}
      />
      <Banner
        color='yellow'
        title='Про Університет'
        description={<p>
          <strong>Навчання:</strong> безкоштовне <br />
          <strong>Студентів:</strong> 2000<br />
          <strong>Бакалаврат:</strong> 3 роки<br /> 
          <strong>Магістратура:</strong> 2 роки<br />
          <strong>Докторантура:</strong> 3 роки денна форма / 4 роки заочна<br />
          <strong>Стипендії:</strong> 500-2500 евро<br />
          <strong>Вступ:</strong> без ЗНО<br />
        </p>}
        img={bannerImg}
        additionalTitle='Про Університет'
        additionalDescr={
          'Університет ветеринарної медицини та фармації (UVLF) в Кошице був заснований і розпочав свою діяльність у 1949/1950 році. UVLF в Кошице є єдиним університетом такого типу в Словаччині. У 1997 році UVLF був оцінений Експертним комітетом Європейського співтовариства і повторно оцінений у 2005 році. Це був перший словацький вищий навчальний заклад, який пройшов комплексну акредитацію і був класифікований як університетський вищий навчальний заклад.'
        }
      />
      <CustomFAQ
        type='2'
        title='Факультети та спеціальності'
      />
      <Application
        id='veterina-form'
        title="Залиште запит"
        page='Veterina'
      />
    </div>
  )
}

export default Veterina
