import React from 'react'
import hero from '../images/hero.svg'
const Hero = ({title, description, link, btnText, img, color, type}) => {
  
  return (
    <React.Fragment>
      <section id='hero' className={`hero ${color} ${type == 2 && 'type2'}`}>
        <div className="container d-flex">
        {type === '2' && (<h1 className="hero--title-mobile">{title}</h1>)}
          <div className="col-12 col-lg-6 d-flex flex-column info">
            <h1 className="hero--title">{title}</h1>
            <p className='mobile--count'>35</p>
            <p className='mobile--descr'>Місць залишилось</p>
            <p className='hero--description'>{description}</p>
            {link ? <a className={color ? `btn btn--invert` : 'btn'} href={link}>{btnText}</a> : ''}
          </div>
          {(type === '2') ? (
            <div className="col-12 col-lg-6 list--wrapper">
              <ul>
                <li>100% Гарантія житла</li>
                <li>Сучасний гуртожиток</li>
                <li>Включає пакет Premium</li>
                <li>Включає пакет Extra</li>
                <li>Повна підтримка під час всього проживання</li>
                <li>Гуртожиток тільки для дівчат</li>
                <li>Сучасна система безпеки</li>
              </ul>
            </div>
          ) : (
            <div className="col-12 col-lg-6 image--wrapper">
              <img src={img} alt="" />
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  )
}

export default Hero
