import React, { useState } from 'react'
import logo from '../images/logo_header.png'
import hamburger from '../images/hamburger.svg'
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';
const Header = () => {

  function setStartPage (){
    window.scrollTo(0, 0);
    const header = document.querySelector("#header");
    const hamburger = header.querySelector(".hamburger")
   
    hamburger && hamburger.classList.remove('open')
  }
  function setMobileNavigation(){
    const header = document.querySelector("#header");
    const mobileNav = header.querySelector(".mobile");
    const hamburger = header.querySelector(".hamburger")
    if(mobileNav.classList.contains("active")){
      mobileNav.classList.remove("active")
      hamburger.classList.remove('open')
    } else{
      mobileNav.classList.add("active")
      hamburger.classList.add('open')
    }
    const navBtns = mobileNav.querySelectorAll('a')
    for(const navBtn of navBtns){
      navBtn.addEventListener("click", function(){
        mobileNav.classList.remove("active")
      })
    }
  }
  return (
    <header id='header' className='header'>
        <div className="container">
            <Link onClick={setStartPage} to="/"><img src={logo} alt="logo" className="header--logo" /></Link>
            <div onClick={setMobileNavigation} className="hamburger">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav className="header--navigation">
              <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/'>Головна</NavLink>
              <Link onClick={setStartPage} to="/#universities">Університети</Link>
              <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/price'>Вартість</NavLink>
              <Link onClick={setStartPage} to="/#about">Про нас</Link>
              {/* <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/edueasy'>Edu: Easy</NavLink> */}
            </nav>
        </div>
        <div className="mobile">
          <div className="container">
          <nav className="header--navigation">
              <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/'>Головна</NavLink>
              <Link onClick={setStartPage} to="/#universities">Університети</Link>
              <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/price'>Вартість</NavLink>
              <Link onClick={setStartPage} to="/#about">Про нас</Link>
              {/* <NavLink onClick={setStartPage} className={({isActive}) => isActive ? "active" : ""} to='/edueasy'>Edu: Easy</NavLink> */}
            </nav>
          </div>
        </div>      
    </header>
  )
}

export default Header
