import React from 'react'
import Hero from '../../components/Hero'
import CustomFAQ from '../../components/CustomFAQ'
import Application from '../../components/Application'
import Banner from '../../components/Banner'
import hero from '../../images/logoTuke.png'
import bannerImg from '../../images/areal_tuke_002.jpg'
const TUKE = () => {
  return (
    <div className='tuke'>
      <Hero 
        title="Технічний університет в Кошице" 
        description="Technická univerzita v Košiciach" 
        btnText="Отримати консультацію"
        link="#tuke-form"
        img={hero}
      />
      <Banner
        color='yellow'
        title='Про Університет'
        description={<p>
          <strong>Навчання:</strong> безкоштовне <br />
          <strong>Студентів:</strong> 9700<br />
          <strong>Бакалаврат:</strong> 3 роки<br />
          <strong>Магістратура:</strong> 2 роки<br />
          <strong>Докторантура:</strong> 3 роки денна форма / 4 роки заочна<br />
          <strong>Стипендії:</strong> 500-2500 евро<br />
          <strong>Вступ:</strong> без ЗНО<br />
        </p>}
        img={bannerImg}
        additionalTitle='Про Університет'
        additionalDescr={
          'Технічний університет в Кошице — це широкий спектр освітніх потреб не тільки в регіоні Східної Словаччини, також університет в багатьох областях є єдиним центром науки, досліджень і освіти не тільки в Словаччині, але і в Центральній Європі. Він працює в тісній співпраці з іншими університетами і з промисловим минулим регіону і всієї Словаччини. \n\nВ останні роки спостерігається значне зростання інтересу до навчання з боку іноземних студентів, що зробило TUKE на міжнародній арені великим навчальним закладом. В університеті навчаються студенти з сорока країн світу. \n\nTUKE забезпечує студентів базою наукових і технологічних знань, інноваціями та кадрами, щоб сформувати сприятливе і стійке майбутнє і якість життя майбутніх випускників. Такий результат TUKE досягає за рахунок інноваційних досліджень і відмінної освіти у всіх наукових областях на окремих факультетах університету. '
        }
      />
      <CustomFAQ
        type='2'
        title='Факультети та спеціальності'
        faq='tuke'
      />
      <Application
        id='tuke-form'
        title="Залиште запит"
        page='Tuke'

      />
    </div>
  )
}

export default TUKE
