import React from 'react'
import Hero from '../components/Hero'
import Teaser from '../components/Teaser'
import Application from '../components/Application'
import CustomFAQ from '../components/CustomFAQ'
import CustomSwiper from '../components/CustomSwiper'
import hero from '../images/eduEasy/hero_edueasy.png'
import Banner from '../components/Banner'
import firstBanner from '../images/aboutUs.svg'
import firstTeaser from '../images/home_1.svg'
import imgSlider1 from '../images/eduEasy/slider/1.JPEG'
import imgSlider2 from '../images/eduEasy/slider/2.JPEG'
import imgSlider3 from '../images/eduEasy/slider/3.JPEG'
import imgSlider4 from '../images/eduEasy/slider/4.JPEG'
import imgSlider5 from '../images/eduEasy/slider/5.JPEG'
import cardImg1 from '../images/eduEasy/bed.svg'
import cardImg2 from '../images/eduEasy/cooking.svg'
import cardImg3 from '../images/eduEasy/laundry.svg'
import cardImg4 from '../images/eduEasy/studying.svg'
const EduEasy = () => {
  return (
    <section className='eduEasy'>
      <Hero
        title="100%"
        description="Гарантія житла"
        btnText="Безкоштовна консультація"
        link="#form"
        img={hero}
      />
      <Teaser 
        count='4'
        img1={cardImg1}
        img2={cardImg2}
        img3={cardImg3}
        img4={cardImg4}
        description1="Безпечний гуртожиток"
        backInfo1="Завдяки електричному замку з чіпом та камерам спостереження, ми можемо обіцяти вам, що ваша донька матиме спокійний та безпечний сон."
        description2="Комфортні кімнати" 
        backInfo2="Кожна кімната обладнана власним душем і туалетом для максимального комфорту."
        description3="Сучасне обладнання та швидкий інтернет" 
        backInfo3="Ми розмістили 10 wifi роутерів по всій будівлі, щоб забезпечити високошвидкісний інтернет у кожній частині гуртожитку. У гуртожитку ви знайдете дві повністю обладнані кухні з сучасною технікою. Крім того, ми безкоштовно надаємо нову пральну та сушильну машини, щоб зробити ваше перебування ще більш комфортним."
        description4="Ідеальне середовище для концентрації на навчанні"
        backInfo4="Для студенток, які потребують тиші для навчання, є кімната для занять, що працює цілодобово."
      />
      <Banner
        color="blue"
        title='Безпечна програма для дівчат'
        fullDescription={`        Одним з найважливіших аспектів нашої пропозиції є 100% гарантоване проживання в безпечному гуртожитку для дівчат. Цей сучасний хостел розташований поруч з прямим сполученням з університетами, бізнес-центрами і всього в 10 хвилинах їзди на трамваї від центру міста. Завдяки електричному замку з чіпом та камерам спостереження, ми можемо обіцяти вам, що ваша донька матиме спокійний та безпечний сон.
        Кожна кімната обладнана власним душем і туалетом для максимального комфорту. У гуртожитку ви знайдете дві повністю обладнані кухні з сучасною технікою, де дівчата зможуть готувати свої улюблені страви. Крім того, ми безкоштовно надаємо нову пральну та сушильну машини, щоб зробити ваше перебування ще більш комфортним.
        Для студенток, які потребують тиші для навчання, є кімната для занять, що працює цілодобово. Ми розмістили 10 wifi роутерів по всій будівлі, щоб забезпечити високошвидкісний інтернет у кожній частині гуртожитку.
        Не пропустіть цю унікальну можливість для своєї доньки. З нами вона буде не тільки навчатися, але й жити повноцінним і безпечним життям в Кошице. Приєднуйтесь до нас і пройдіть разом з нами цю незабутню подорож до навчання!`}
        description={`        Ласкаво просимо до нашої спеціальної пропозиції для іноземних студентів, які вирішили навчатися в прекрасному місті Кошице! 
        Мета програми - забезпечити вашій доньці не тільки якісну освіту, а й безпечне та комфортне середовище, в якому вона зможе повністю сконцентруватися на навчанні.
        Наша програма обмежена лише 35 щасливчиками, і ми гарантуємо, що пропонуємо більше, ніж просто навчання. З нашим пакетом EduEasy ви можете бути впевнені, що ваша донька матиме все необхідне для успішного та комфортного навчання в Кошице.`}
        img={firstBanner}
        id='about'
        btn='Дізнатись більше'
      />
      <CustomSwiper
      title='Наші гуртожитки'
      img1={imgSlider1}
      img2={imgSlider2}
      img3={imgSlider3}
      img4={imgSlider4}
      img5={imgSlider5}
      />
      <section className='advantages'>
        <div className="container">
          <h1 className='text-center'>Edu Easy</h1>
          <ul className="advantages--wrapper">
            <li>100% Гарантія житла</li>
            <li>Сучайсний гуртожиток</li>
            <li>Включає пакет Premium</li>
            <li>Включає пакет Extra</li>
            <li>Повна підтримка під час всього проживання</li>
            <li>Гуртожиток тільки для дівчат</li>
          </ul>
        </div>
      </section>
      <Application
        title="Залиш запит"
        description="Отримай безкоштовну консультацію"
        id='form'
        page='eduEasy'
      />
    </section>
  )
}

export default EduEasy;
