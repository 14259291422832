import React from 'react'
import Hero from '../../components/Hero'
import CustomFAQ from '../../components/CustomFAQ'
import Application from '../../components/Application'
import Banner from '../../components/Banner'
import hero from '../../images/upjs_logo.PNG'
import bannerImg from '../../images/rektorat-budova.jpg'
const UPJS = () => {
  return (
    <div className='upjs'>
      <Hero 
        title="Університет Павла Йозефа Шафарика" 
        description="Univerzita Pavla Jozefa Šafárika" 
        btnText="Отримати консультацію"
        link="#upjs-form"
        img={hero}
      />
      <Banner
        color='yellow'
        title='Про Університет'
        description={<p>
          <strong>Навчання:</strong> безкоштовне <br />
          <strong>Студентів:</strong> 12 000<br />
          <strong>Бакалаврат:</strong> 3 роки<br />
          <strong>Магістратура:</strong> 2 роки<br />
          <strong>Аспірантура:</strong> від 3 до 5 років<br />
          <strong>Стипендії:</strong> за успішність і програми з обміну від 1 року навчання від 150 до 1500 євро.<br />
          <strong>Вступ:</strong> Без іспитів і ЗНО (в залежності від спеціалізації).<br />
        </p>}
        img={bannerImg}
        additionalTitle='Про Університет'
        additionalDescr={
          'Університет Павла Йозефа Шафарика в Кошице належить до визнаних освітніх і наукових установ не тільки в Словацькій Республіці, але і в Європі. Це другий найстаріший університет у Словаччині. Університет спеціалізується на наданні трирівневу вищу освіту на основі наукових знань в міжнародному контексті, відповідно до європейських тенденцій в цій галузі і Болонської декларацією. Університет виконує цю місію, розвиваючи гармонійну особистість, процвітаючи в знаннях, підвищуючи мудрість і творчі здібності людини. Університет веде студентів до толерантності, здатності критично і незалежно мислити, до здорової впевненості в собі і національної гордості. Університет надає послуги розповсюдження досліджень за допомогою досліджень і розробок, підтримуючи регіональні та національні заходи.'
        }
      />
      <CustomFAQ
        type='2'
        faq='upjs'
        title='Факультети та спеціальності'

      />
      <Application
        title="Залиште запит"
        id="upjs-form"
        page='UPJS'
      />
    </div>
  )
}

export default UPJS
