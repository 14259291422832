import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import bg from '../images/bg.jpg'
import { Document } from 'react-pdf'
import FormPopUp from './FormPopUp';
const PriceModule = ({id,headline,link, title1, title2, title3}) => {

  return (
    <React.Fragment>
      <section id={id} className='section--price'>
        <div className="container w-lg-75">
            {headline && <h3 className='text-center'>{headline}</h3>}
            <div className="cards d-flex gap-5">
              <Link className="card w-lg-50" to={link}>
                  <div className="img">
                    <p className="title">{title1}</p>
                  </div>
                  <div className="text--wrapper p-4">
                  <p><strong>В пакет входить:</strong></p>
                    <ul className="card--description">
                      <li>Допомога з вибором університету та спеціальності</li>
                      <li>Допомога в підборі викладача словацької мови / Запис на групові курси словацької мови</li>
                      <li>Підготовка пакетів документів до подачі в університети</li>
                      <li>Подача документів у 2 обрані університети,відповідно до вимог кожного з них</li>
                      <li>Ведення переговорів з університетом на всіх етапах вступу</li>
                      <li>Бронювання гуртожитку</li>
                      <li>Реєстрація на безкоштовні підготовчі курси (відповідно до пропозицій університету)</li>
                      <li>Реєстрація на обовязковий медогляд</li>
                      <li>Оформлення повного пакету документів для подачі в міграційну поліцію Словаччини</li>
                      <li>Консультування по будь яким питанням до початку навчвчання</li>
                      <li><strong>Всі витрати на оформлення паперів входять у вартість</strong></li>
                    </ul>
                    <ul className='grey'>
                      <li>Супровід при записі в університет</li>
                      <li>Супровід при поселенні в гуртожиток</li>
                      <li>Супровід при подачі документів на міграційній поліції</li>
                      <li>Супровід при отриманні ISIC карти (студентського)</li>
                      <li>Супровід при подачі документів на міграційну поліцію</li>
                      <li>Супровід на обовязковий медогляд</li>
                      <li>Оформлення проїздного для громадського транспорту</li>
                      <li>Оформлення карти для безкоштовного проїзду по всій Словаччині</li>
                      <li>Повний супровід у відкритті банківського рахунку</li>
                      <li>Оформлення SIM-карти</li>
                      <li>Трансфер з України (Ужгород) до місця проживання в Словаччині</li>
                    </ul>
                  </div>
              </Link>
              <Link className="card w-lg-50" to={link}>
                  <div className="img">
                    <p className="title">{title3}</p>
                  </div>
                  <div className="text--wrapper p-4">
                    <p><strong>В пакет входить:</strong></p>
                    <ul className="card--description">
                      <li>Допомога з вибором університету та спеціальності</li>
                      <li>Допомога в підборі викладача словацької мови / Запис на групові курси словацької мови</li>
                      <li>Підготовка пакетів документів до подачі в університети</li>
                      <li>Подача документів у 2 обрані університети,відповідно до вимог кожного з них</li>
                      <li>Ведення переговорів з університетом на всіх етапах вступу</li>
                      <li>Бронювання гуртожитку</li>
                      <li>Реєстрація на безкоштовні підготовчі курси (відповідно до пропозицій університету)</li>
                      <li>Реєстрація на обовязковий медогляд</li>
                      <li>Оформлення повного пакету документів для подачі в міграційну поліцію Словаччини</li>
                      <li>Консультування по будь яким питанням до початку навчвчання</li>
                      <li><strong>Всі витрати на оформлення паперів входять у вартість</strong></li>
                    </ul>
                    <ul>
                      <li>Супровід при записі в університет</li>
                      <li>Супровід при поселенні в гуртожиток</li>
                      <li>Супровід при подачі документів на міграційній поліції</li>
                      <li>Супровід при отриманні ISIC карти (студентського)</li>
                      <li>Супровід при подачі документів на міграційну поліцію</li>
                      <li>Супровід на обовязковий медогляд</li>
                      <li>Оформлення проїздного для громадського транспорту</li>
                      <li>Оформлення карти для безкоштовного проїзду по всій Словаччині</li>
                      <li>Повний супровід у відкритті банківського рахунку</li>
                      <li>Оформлення SIM-карти</li>
                      <li>Трансфер з України (Ужгород) до місця проживання в Словаччині</li>
                    </ul>
                  </div>
              </Link>
            </div>
            <FormPopUp btnText='Дізнатись ціни' page='Price-download'/>
        </div>
      </section>
    </React.Fragment>
  )
}

export default PriceModule
