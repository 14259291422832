import React, {useState} from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { getDatabase, ref, set } from "firebase/database"
import pdf from '../images/prices.pdf'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useRef } from 'react';
function writeUserData(name, email, phoneNumber, date, page) {
    const db = getDatabase();
    
    set(ref(db, 'users/' + name), {
      userName: name,
      email: email,
      phoneNumber : phoneNumber,
      date : date,
      page : page
    });
  }

const FormPopUp = ({title, description, link, id, type, btnText, submit, page}) => {
    const date = new Date()
  
  const onSubmit = (event) => {
    event.preventDefault();
    const a = event.target[0].value;
    const b = event.target[1].value;
    const c = event.target[2].value;
    const finalDate = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    
    writeUserData(a, b, c, finalDate, page);

    const link = document.querySelector('#downloadPdf');
    link.click()
    const popup =document.querySelector(".form.popup");
    popup.classList.add("success")
    popup.querySelector(".popup--result").classList.remove("d-none")
  }

  const [value, setValue] = useState()
  const ref = useRef();
  const closeTooltip = () => ref.current.close();

  return (
    <Popup ref={ref} open={false} modal nested trigger={(type == 'edueasy') ? (<button id="AppSubmit" type='submit' className="btn">{btnText}</button>) : (<a className="btn btn--invert">Дізнатись ціни</a>)} position="center center">
        <React.Fragment>
            {(type == 'edueasy')
            ?
            (
              <section id={id && id} className='form popup'>
              <a className='closeBtn' onClick={closeTooltip} ><svg id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"/><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"/></g></g></svg></a>
              <div className="container d-flex justify-content-center w-100">
                  <div className="popup--result">
                    <h4 className='text-center mb-3'>Дякуємо за заявку !</h4>
                    <p className='text-center mb-0'>Найближчим часом з вами звяжеться наш менеджер. На ваш імейл були відправлені додаткові матеріали для ознайомлення.</p>
                  </div>
              </div>
            </section>
            )
            :
            (
              <section id={id && id} className='form popup'>
              <a className='closeBtn' onClick={closeTooltip} ><svg id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"/><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"/></g></g></svg></a>
              <h5>Після натиснення кнопки файл буде завантажено на ваш пристрій та відправлено на e-mail</h5>
              <div className="container d-flex justify-content-center w-100">
                  <form className='d-flex flex-column align-items-center' action="submit" onSubmit={onSubmit}>
                    {title && <h2 className="title text-center">{title}</h2>}
                    {description && <p className="description text-center">{description}</p>}
                    <input required className='p-2' name="name" type="text" placeholder='Прізвище та ім’я' />
                    <input required className='p-2' name="email" type="email" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" placeholder='Email' />
                    <PhoneInput
                      isValid={(value, country) => {
                        const submit = document.getElementById("submit");
                        if (!value.match(/[0-9]{12}/g)) {
                          if(submit) submit.disabled = true;
                          return 'Некоректний номер: '+value+'';
                        } else {
                          if(submit) submit.disabled = false;
                        }
                      }}
                      required
                      country={'ua'}
                      placeholder="Телефон"
                      value={value}
                      onChange={setValue}/>
                    <div className="popup--result d-none">
                      <h4 className='text-center mb-3'>Дякуємо!</h4>
                      <p className='text-center mb-0'>На ваш Email були відправлені пакети з цінами та додаткові матеріали для ознайомлення, також файл з цінами завантажився на ваш пристрій.</p>
                    </div>
                    <a id='downloadPdf' download="eduKE Ціни" href={pdf} target='_blank'></a>
                    <input id='submit' disabled className="btn d-flex justify-content-center" type="submit" value="Отримати ціни"/>
                  </form>
              </div>
            </section>
            )
            }
        </React.Fragment>
    </Popup>
  )
}

export default FormPopUp
