import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const CustomSwiper = ({img1, img2, img3, img4, img5, title}) => {
  return (
    <React.Fragment>
      <section className="slider">
        <div className="container">
          {title && (<h3 className='mb-3'>{title}</h3>)}
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            navigation
            autoplay={{
              delay: 3000
            }}
            loop={true}
            pagination={{ clickable: true }}
            slidesPerView={1}
          >
            <SwiperSlide><img src={img1} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img2} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img3} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img4} alt="" /></SwiperSlide>
            <SwiperSlide><img src={img5} alt="" /></SwiperSlide>
          </Swiper>
        </div>
      </section>
    </React.Fragment>
  )
}

export default CustomSwiper
