import React from 'react'
import Hero from '../components/Hero'
import Teaser from '../components/Teaser'
import Application from '../components/Application'
import CustomFAQ from '../components/CustomFAQ'
import CustomSwiper from '../components/CustomSwiper'
import TextImage from '../components/TextImage'
import Banner from '../components/Banner'
import firstTeaser from '../images/home_1.svg'
import secondTeaser from '../images/home_2.svg'
import thirdTeaser from '../images/home_3.svg'
import fourthTeaser from '../images/home_4.svg'
import firstBanner from '../images/aboutUs.svg'
import secondBanner from '../images/Teaching-amico (1).svg'
import firstUniv from '../images/rektorat-budova.jpg'
import secondUniv from '../images/areal_tuke_002.jpg'
import thirdUniv from '../images/veterina.jpg'
import slider1 from '../images/aljbeta.jpeg'
import slider2 from '../images/Kosice.jpg'
import slider3 from '../images/stadion.jpg'
import slider4 from '../images/tuke-kopie.jpg'
import hero from '../images/home/hero_yellow2.png'
// import Video from '../components/Video'



// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};




// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.


// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lc4tRApAAAAADNh1L4NGW3HFE9-pYo-5q8_tiqd'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});
const Home = () => {

  
  
  return (
    <React.Fragment>
      {/* <Video/> */}
      <Hero 
        title="Безкоштовна освіта в Словаччині" 
        description="Пройдемо цей шлях разом - від першої заяви до першого візиту до університету!" 
        btnText="Безкоштовна консультація"
        link="#form"
        img={hero}
      />
      <Banner
        color="blue"
        title='У Кошице ми вдома!'
        fullDescription={`      Наша команда україномовних співробітників буде поруч з вами протягом усього навчального року, готова допомогти вам з будь-якими питаннями або проблемами, які можуть виникнути під час навчання. Незалежно від того, чи це стосується проживання, адміністративних питань або інших студентських проблем, ми тут для вас. Агенція edu:KE також надає юридичну підтримку, щоб заспокоїти та проконсультувати вас, якщо у вас виникнуть будь-які проблеми. Ваш студентський час повинен бути незабутнім і безтурботним, наша агенція допоможе вам в цьому. Увійдіть у світ освіти з впевненістю і довірою, ми тут, щоб допомогти вам досягти ваших цілей.`}
        description={`      eduKE спеціалізується на підготовці до навчання виключно в Кошице, тому ми можемо забезпечити студентам швидкий розгляд заявок та безпеку проживання в наших сучасних студентських гуртожитках. З власного досвіду ми знаємо, що для успішного навчання студенти також повинні мати належні умови. Саме тому ми приділяємо особливу увагу якості проживання та наданню комплексних послуг протягом усього навчального року. Ми одна з небагатьох агенцій, яка може показати вам житло, в якому ви будете проживати, ще до початку навчання.`}
        img={firstBanner}
        id='about'
        btn='Дізнатись більше'
      />
      {/* <Hero 
        title="Edu: Easy" 
        description="Безпечна програма для дівчат
Залишилось 35 місць" 
        btnText="Дізнатися більше"
        link="/edueasy"
        type='2'
        img={hero}
      /> */}
      <Teaser 
        img1={firstTeaser}
        img2={secondTeaser} 
        img3={thirdTeaser}
        img4={fourthTeaser}
        backInfo1='Результати ЗНО при вступі не враховуються. Також для вступу до більшості університетів Словаччини не потрібно складати зовнішні та вступні іспити. Для уточнення інформації щодо обраної вами спеціпльності залиште заявку та наш менеджер звя`жеться з вами.'
        backInfo2='ВНЖ Словаччини надає можливість офіційного працевлаштування під час навчання. Працювати можна до 80 годин на місяць.'
        backInfo3='В деяких університетах є можливість отримати мотиваційну стипендію в кінці кожного семестру. Стипендія може коливатися від 500 до 2500 Євро'
        backInfo4='Словацьке ВНЖ надає можливість подорожувати по всій Європі. Також ISIC ( студентське посвідченнч європейського взірця ) дозволяє вам безкоштовно подорожувати потягами по всій Словакії.'
        description1="Вступай без ЗНО та вступних іспитів"
        description2="Вчись безкоштовно в університетах Словаччини" 
        description3="Отримуй стипендію в європейському вузі" 
        description4="Працюй та подорожуй зі студентським Європейського взірця."
        count='4'
        link='form'
      />
      <Application
        title="Залиш запит"
        description="Отримай безкоштовну консультацію"
        id='form'
        page='Home'

      />
      {/* <TextImage/> */}
      <Teaser 
        img1={firstUniv}
        img2={secondUniv} 
        img3={thirdUniv}
        description1="Вищий навчальний заклад зі 100 річною історією з ухилом на гуманітарні науки"
        description2="Лідируючий університет в Словаччині коли мова йде про технічні спеціальності. Робить ухил на практичні заняття"
        description3="Є єдиним у своєму роді закладом університетської ветеринарної освіти та одним з двох закладів фармацевтичної освіти в Словацькій Республіці"
        title1="Університет Павла Йозефа Шафарика"
        title2="Кошицький Технічний Університет"
        title3="Університет ветеринарної справи та фармацевтики в Кошице"
        id="universities"
        headline='Університети'
        firstLink='/upjs-university'
        secondLink='/tuke-university'
        thirdLink='/veterina-university'

      />
      <Banner
        title='Вивчення словацької мови'
        description={<ul>
          <li>Онлайн курси словацької мови</li>
          <li>Літній буткемп вивчення мови</li>
          <li>Оффлайн курси перед навчанням в місті навчання</li>
          <li>Оффлайн курси у вашому місті (за наявністю)</li>
        </ul>}
        link='/'
        img={secondBanner}
        btnText='Детальніше'
        
      />
      <CustomSwiper
          img1={slider1}
          img2={slider2}
          img3={slider3}
          img4={slider4}
      />
    
      <CustomFAQ
      type='1'
      title='Поширені запитання'
      />
      <Application
        title="Залиш запит"
        description="Отримай безкоштовну консультацію"
        id='form'
        page='Home'

      />
    </React.Fragment>
  )
}

export default Home
