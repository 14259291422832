import React from 'react'

const Banner = ({id, imgPos, title, description, fullDescription, link, btnText, img, color, additionalTitle, additionalDescr, btn}) => {
    function handleClick(e) {
        e.target.style.display = 'none';
        document.querySelector('#bannerImg').classList.add("clicked")
    }
  return (
    <React.Fragment>
        <section id={id && id} className={`banner ${color}`}>
            {(imgPos == "right") 
            ? 
            (<div className="container d-flex">
                <div className="col-12 col-lg-6">
                    <h3>{title}</h3>
                    <p className="description">{description}</p>
                    {link && <a className='btn' href={link}>{btnText}</a>}
                </div>
                <img src={img} alt='' className="col-12 col-lg-6"/>
            </div>
            ) 
            : 
            (<div className="container">
                <div className="banner--wrapper mb-3">
                    <img id='bannerImg' src={img} alt='' className="col-12 col-lg-6"/>
                    <div className="col-12 col-lg-6">
                        <h3 className='mb-4'>{title}</h3>
                        <p className={`description ${fullDescription && 'mb-0'}`}>{description}</p>
                        {fullDescription && (
                            <details>
                                <summary onClick={handleClick} className={`btn mt-lg-4 ${color && 'btn--invert'}`}>{btn}</summary>
                                <p className="description">{fullDescription}</p>
                            </details>
                        )}
                        {/* {link && <a className='btn' href={link}>{btnText}</a>} */}
                    </div>
                </div>
                {additionalTitle && (
                    <div className="col-12">
                        <h3>{additionalTitle}</h3>
                        <p className="description">{additionalDescr}</p>
                    </div>
                )}
            </div>)}
        </section>
    </React.Fragment>
  )
}

export default Banner
