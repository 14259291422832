import Footer from "./components/Footer";
import Header from "./components/Header";
import "./scss/all.scss";
import Home from "./pages/Home";
import Language from "./pages/EduEasy";
import React, { useEffect } from "react";
import {
  Routes,
  Route
} from "react-router-dom";
import Price from "./pages/Price";
import TUKE from "./pages/universities/TUKE";
import UPJS from "./pages/universities/UPJS";
import Veterina from "./pages/universities/Veterina";
import EduEasy from "./pages/EduEasy";

function App() {
  
  
  
  return (
    <React.Fragment>
      <Header/>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/edueasy" element={<EduEasy />} />
        <Route path="/price" element={<Price />} />
        <Route path="/tuke-university" element={<TUKE />} />
        <Route path="/upjs-university" element={<UPJS />} />
        <Route path="/veterina-university" element={<Veterina />} />

      </Routes>
      <Footer/>
    </React.Fragment>
  );
}

export default App;
