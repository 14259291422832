import React from 'react'
import background1 from '../images/leave_request_1.svg'
import background2 from '../images/leave_request_2.svg'
import { getDatabase, ref, set } from "firebase/database"
import FormPopUp from './FormPopUp';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router';
import { useEffect } from 'react';


function writeUserData(name, email, phoneNumber, date, eduEasy) {
  const db = getDatabase();
  
  set(ref(db, 'users/' + name), {
    userName: name,
    email: email,
    phoneNumber : phoneNumber,
    date : date,
    page : eduEasy
  });
}

const Application = ({title, description, link, id, page}) => {
  const date = new Date()
  
  const onSubmit = (event) => {
    event.preventDefault();
    const a = event.target[0].value;
    const b = event.target[1].value;
    const c = event.target[2].value;
    const finalDate = date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + ' ' + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    writeUserData(a, b, c, finalDate, page ? page : 'undefined');
    setSubmit(true)

    const forms = document.querySelectorAll('form');
    forms && forms.forEach(form => {
      form.reset();
      setValue('+38');
      return false;
    })
  }
  const [value, setValue] = useState()
  const [submit, setSubmit] = useState(false)
  const location = useLocation();

  useEffect(() => {
    const forms = document.querySelectorAll('form');
    forms && forms.forEach(form => {
      form.querySelector(".btn").disabled = true;
    });
  }, [location]);
 
  const formValid = () => {
    let submit;
    const forms = document.querySelectorAll('form');
    forms.forEach(form => {
      submit = form.querySelector('.btn')
      form.checkValidity() ? (submit.disabled = false) : (submit.disabled = true)
    });                
  }
  return (
    <React.Fragment>
      <section name='form' id={id && id} className='form'>
        <div className="container d-flex justify-content-between">
          <img src={background1} alt="" />
          <form className='d-flex flex-column align-items-center' action="submit" onSubmit={onSubmit}>
            <h2 className="title text-center">{title}</h2>
            {description && <p className="description text-center">{description}</p>}
            <input onChange={formValid} required className='p-2' name="name" type="text" placeholder='Прізвище та ім’я' />
            <input onChange={formValid} required className='p-2' name="email" type="email" pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$" placeholder='Email' />
            <PhoneInput
              isValid={(value) => {
                const submit = document.getElementById("AppSubmit");
                if (!value.match(/[0-9]{12}/g) && !value.match(/[0-9]{13}/g)) {
                  if(submit) submit.disabled = true;
                  return ' ';
                } else {
                  if(submit) submit.disabled = false;
                }
              }}
              required
              country={'ua'}
              placeholder="Телефон"
              value={value}
              onChange={setValue}/>
            <FormPopUp btnText='Надіслати' type='edueasy' submit={submit} />
          </form>
          <img src={background2} alt="" />
        </div>
      </section>
    </React.Fragment>
  )
}

export default Application
